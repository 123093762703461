import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Check specifically for chunk loading errors
    if (error.name === 'ChunkLoadError' || 
        (error.message && error.message.includes('Loading chunk'))) {
      return { hasError: true };
    }
    return { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error for debugging
    console.error("ErrorBoundary caught an error:", error, errorInfo);

    // Handle chunk load errors by reloading the page
    if (error.name === 'ChunkLoadError' || 
        (error.message && error.message.includes('Loading chunk'))) {
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      return <div>Loading... Please wait.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;