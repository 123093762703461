import { flow, types } from "mobx-state-tree";
import { notification } from "antd";
import { api } from "../constants/api";
import { axios } from "../services/http/axios";

const AuthStore = types
    .model("AuthStore", {
        clientSecret: types.maybeNull(types.string),
        register: types.maybeNull(
            types.model("Register", {
                email: types.maybeNull(types.string),
                userName: types.maybeNull(types.string),
            }),
        ),
        stripeVerificationStatus: types.maybeNull(
            types.model("StripeVerificationStatus", {
                detailsSubmitted: types.boolean,
                hasExternalAccount: types.boolean,
                cardPaymentsActive: types.boolean,
                transfersActive: types.boolean,
                currentlyDueFields: types.array(types.string),
                eventuallyDueFields: types.array(types.string),
                pastDueFields: types.array(types.string),
                isFullyVerified: types.boolean
            })
        ),
        me: types.maybeNull(
            types.model("MeModel", {
                id: types.maybeNull(types.string),
                userName: types.maybeNull(types.string),
                displayName: types.maybeNull(types.string),
                email: types.maybeNull(types.string),
                avatar: types.maybeNull(types.string),
                bio: types.maybeNull(types.string),
                totalRevenue: types.maybeNull(types.number),
                storeVisits: types.maybeNull(types.number),
                leads: types.maybeNull(types.number),
                hasVisitedDashboard: types.optional(types.boolean, false),
                metrics: types.optional(types.array(types.frozen()), []),
                isEmailVerified: types.optional(types.boolean, false),
                isVerified: types.optional(types.boolean, false),
                isClaimed: types.optional(types.boolean, true),
                isShopDisplayed: types.optional(types.boolean, false),

                socialLinks: types.optional(
                    types.model("SocialLinks", {
                        instagram: types.maybeNull(types.string),
                        tiktok: types.maybeNull(types.string),
                        youtube: types.maybeNull(types.string),
                        twitter: types.maybeNull(types.string),
                        facebook: types.maybeNull(types.string),
                        email: types.maybeNull(types.string),
                        discord: types.maybeNull(types.string),
                        twitch: types.maybeNull(types.string),
                        threads: types.maybeNull(types.string),
                        linkedin: types.maybeNull(types.string),
                        pinterest: types.maybeNull(types.string),
                        custom: types.maybeNull(types.string),
                        // Add more fields as needed
                    }),
                    {}
                ),
                stripeCustomerId: types.maybeNull(types.string),
                stripeAccountId: types.maybeNull(types.string),
            }),
        ),
        products: types.optional(types.array(types.frozen()), []),
        headlineSections: types.optional(types.array(types.frozen()), []),
        theme: types.maybeNull(
            types.model("ThemeModel", {
                background_color: types.maybeNull(types.string),
                card_color: types.maybeNull(types.string),
                background_type: types.maybeNull(types.string),
                gradient_direction: types.maybeNull(types.string),
                button_color: types.maybeNull(types.string),
                button_text_color: types.maybeNull(types.string),
                card_text_color: types.maybeNull(types.string),
                card_style: types.maybeNull(types.string),
                shadow_color: types.maybeNull(types.string),
                background_image: types.maybeNull(types.string),
                background_video: types.maybeNull(types.string),
                font_family: types.maybeNull(types.string),
                button_style: types.maybeNull(types.string),
                hover_animation: types.maybeNull(types.boolean),
                layout: types.maybeNull(types.string),
                show_profile_picture: types.maybeNull(types.boolean),
                show_social_links: types.maybeNull(types.boolean),
                custom_css: types.maybeNull(types.string),
                image_style: types.maybeNull(types.string),
                font_color: types.maybeNull(types.string),
            })
        ),
    })
    .actions((self) => ({
        setToken: (token) => {
            localStorage.setItem("SparkleAuthToken", token);
        },
        setUserData: (data) => {
            self.register = data;
        },
        setProfileData: (data) => {
            self.me = data;
        },
        setHasVisitedDashboard: (value) => {
            self.me.hasVisitedDashboard = value;
        },
        checkUserNameAndEmail: flow(function* (values) {
            try {
                return yield axios.post(api.auth.check, values);
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "checkUserNameAndEmail: Something went wrong",
                });
                throw error;
            }
        }),
        getStoreData: flow(function* (storeName) {
            try {
                const { data } = yield axios.get(api.store.userStoreData(storeName));
                self.products = data.products;
                self.headlineSections = data.headlineSections;
                self.me = {
                    ...self.me,
                    fullName: data.user.fullName,
                    avatar: data.user.avatar,
                    bio: data.user.bio,
                    socialLinks: data.user.socialLinks,
                    storeName: storeName,
                    userName: storeName,
                    displayName: data.user.displayName,
                    isVerified: data.user.isVerified,
                    isClaimed: data.user.isClaimed,
                    isShopDisplayed: data.user.isShopDisplayed,
                };
            } catch (error) {
                console.error(error.response?.data?.message);
                throw error;
            }
        }),

        incrementViewCount: flow(function* (storeName) {
            try {
                yield axios.post(api.store.updateViewCount(storeName));
            } catch (error) {
                console.error('Failed to update view count', error);
            }
        }),

        claimAccount: flow(function* (storeName, formValues) {
            try {
                yield axios.post(api.store.claimAccount(storeName), formValues);
            } catch (error) {
                console.error('Failed to claim account', error);
                throw error;
            }
        }),

        incrementShopViewCount: flow(function* (storeName) {
            try {
                yield axios.post(api.store.updateShopViewCount(storeName));
            } catch (error) {
                console.error('Failed to update shop view count', error);
                notification.error({
                    message: error.response?.data?.message || "incrementShopViewCount: Something went wrong",
                });
            }
        }),

        incrementProductClick: flow(function* (storeName, productId, productType) {
            try {
                yield axios.post(api.store.updateProductClick(storeName, productId, productType));
            } catch (error) {
                console.error('Failed to update product click count', error);
            }
        }),

        getCheckoutData: flow(function* (storeName, productSlug, baseUrl) {
            try {
                const { data } = yield axios.get(api.store.checkout(storeName, productSlug, baseUrl));

                // Check if the data includes a booking product
                if (data.bookingProduct) {
                    // Parse the availability data if it's a string
                    if (typeof data.bookingProduct.availability === 'string') {
                        data.bookingProduct.availability = JSON.parse(data.bookingProduct.availability);
                    }
                    data.isBookingProduct = true;
                }

                return data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "getCheckoutData: Something went wrong",
                });
                throw error;
            }
        }),
        updateGoal: flow(function* (values) {
            try {
                yield axios.post(api.auth.goal, values);
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "updateGoal: Something went wrong",
                });
                throw error;
            }
        }),
        setupIntent: flow(function* () {
            try {
                const { data } = yield axios.post(api.billing.setup());
                self.clientSecret = data.clientSecret
                return data.clientSecret;
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "setupIntent: Something went wrong",
                });
                throw error;
            }
        }),
        onSetupSuccess: flow(function* (setupIntent) {
            try {
                yield axios.post(api.billing.confirm(), { setupIntent });
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "onSetupSuccess: Something went wrong",
                });
                throw error;
            }
        }),
        updateNiche: flow(function* (values) {
            try {
                yield axios.post(api.auth.niche, values);
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "updateNiche: Something went wrong",
                });
                throw error;
            }
        }),
        updateTemplate: flow(function* (values) {
            try {
                yield axios.post(api.auth.template, values);
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "updateTemplate: Something went wrong",
                });
                throw error;
            }
        }),
        updateSocialLinks: flow(function* (values) {
            try {
                yield axios.post(api.auth.socialLinks, values);
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "updateSocialLinks: Something went wrong",
                });
                throw error;
            }
        }),
        updateBio: flow(function* (values) {
            try {
                yield axios.post(api.auth.bio, values);
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "updateBio: Something went wrong",
                });
                throw error;
            }
        }),
        updateText: flow(function* (values) {
            try {
                yield axios.post(api.auth.bioText, values, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "updateBio: Something went wrong",
                });
                throw error;
            }
        }),

        onCreateUser: flow(function* (values) {
            try {
                const result = yield axios.post(api.auth.signUp, values);
                self.setToken(result.data.token);
                //self.setProfileData(result.data.profile);

                return true;
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "onCreateUser: Something went wrong",
                });
                throw error;
            }
        }),
        onLogin: flow(function* (values) {
            try {
                const result = yield axios.post(api.auth.signIn, values);
                self.setToken(result?.data?.token);

                return true;
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "onLogin: Something went wrong",
                });
                throw error;
            }
        }),
        logout: () => {
            self.me = null;
            localStorage.clear();
        },
        whoami: flow(function* () {
            try {
                const result = yield axios.get(api.auth.whoami);
                if (result.data?.profile) {
                    self.me = {
                        ...result.data.profile,
                        isEmailVerified: result.data.profile.isEmailVerified || false,
                    };
                }
            } catch (error) {
                console.log(error);
            }
        }),

        //User Profile
        fetchUserDetailsMyStore: flow(function* () {
            try {
                const result = yield axios.get(api.userProfile.fetch);
                const userProfile = result.data;

                // Ensure the structure matches MeModel
                self.me = {
                    id: userProfile._id,
                    userName: userProfile.userName,
                    displayName: userProfile.displayName,
                    email: userProfile.email,
                    bio: userProfile.bio,
                    avatar: userProfile.avatar,
                    totalRevenue: userProfile.totalRevenue || 0,
                    storeVisits: userProfile.storeVisits || 0,
                    leads: userProfile.leads || 0,
                    hasVisitedDashboard: userProfile.hasVisitedDashboard || false,
                    isVerified: userProfile.isVerified || false,
                    isClaimed: userProfile.isClaimed,
                    isShopDisplayed: userProfile.shopSettings.isDisplayed,
                    socialLinks: {
                        instagram: userProfile.socialLinks.instagram || '',
                        tiktok: userProfile.socialLinks.tiktok || '',
                        youtube: userProfile.socialLinks.youtube || '',
                        twitter: userProfile.socialLinks.twitter || '',
                        facebook: userProfile.socialLinks.facebook || '',
                        email: userProfile.socialLinks.email || '',
                        discord: userProfile.socialLinks.discord || '',
                        twitch: userProfile.socialLinks.twitch || '',
                        threads: userProfile.socialLinks.threads || '',
                        linkedin: userProfile.socialLinks.linkedin || '',
                        pinterest: userProfile.socialLinks.pinterest || '',
                        custom: userProfile.socialLinks.custom || ''
                    }
                };
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchUserDetailsMyStore: Something went wrong",
                });
                throw error;
            }
        }),

        fetchUserDetails: flow(function* () {
            try {
                const result = yield axios.get(api.userProfile.fetch);
                const userProfile = result.data;

                // Strip URLs back to usernames
                const socialLinks = userProfile.socialLinks || {};
                const updatedSocialLinks = {
                    instagram: socialLinks.instagram ? socialLinks.instagram.replace(/^(https?:\/\/)?(www\.)?instagram\.com\//, '') : '',
                    tiktok: socialLinks.tiktok ? socialLinks.tiktok.replace(/^(https?:\/\/)?(www\.)?tiktok\.com\/@/, '') : '',
                    twitter: socialLinks.twitter ? socialLinks.twitter.replace(/^(https?:\/\/)?(www\.)?twitter\.com\//, '') : '',
                    youtube: socialLinks.youtube || '',
                    facebook: socialLinks.facebook || '',
                    email: socialLinks.email || '',
                    discord: socialLinks.discord || '',
                    twitch: socialLinks.twitch || '',
                    threads: socialLinks.threads || '',
                    linkedin: socialLinks.linkedin || '',
                    pinterest: socialLinks.pinterest || '',
                    custom: socialLinks.custom || ''
                };

                // Ensure the structure matches MeModel
                self.me = {
                    id: userProfile._id,
                    userName: userProfile.userName,
                    fullName: userProfile.fullName,
                    displayName: userProfile.displayName,
                    isClaimed: userProfile.isClaimed,
                    isShopDisplayed: userProfile.isShopDisplayed,
                    email: userProfile.email,
                    phone: userProfile.phone,
                    bio: userProfile.bio,
                    avatar: userProfile.avatar,
                    totalRevenue: userProfile.totalRevenue || 0,
                    storeVisits: userProfile.storeVisits || 0,
                    leads: userProfile.leads || 0,
                    hasVisitedDashboard: userProfile.hasVisitedDashboard || false,
                    socialLinks: updatedSocialLinks, // Update socialLinks with stripped URLs
                    stripeAccountId: userProfile.stripeAccountId,
                    address: userProfile.address,
                    analytics: userProfile.analytics,
                    isEmailVerified: userProfile.isEmailVerified || false, 
                    isVerified: userProfile.isVerified,
                };

                return userProfile;

            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchUserDetails: Something went wrong",
                });
                throw error;
            }
        }),

        fetchIsFirstVisit: flow(function* () {
            try {
                const result = yield axios.get(api.userProfile.fetch);
                self.me.hasVisitedDashboard = result.data.hasVisitedDashboard || false;
                return !self.me.hasVisitedDashboard; // Return true if it's the first visit
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchIsFirstVisit: Something went wrong",
                });
                throw error;
            }
        }),

        updateUser: flow(function* (userData) {
            try {
                const result = yield axios.put(api.userProfile.update, userData);
                const updatedUserProfile = result.data;

                self.me = {
                    id: updatedUserProfile._id,  // Ensure this matches your model definition
                    userName: updatedUserProfile.userName,
                    displayName: updatedUserProfile.displayName,
                    email: updatedUserProfile.email,
                    avatar: updatedUserProfile.avatar,
                    bio: updatedUserProfile.bio,
                    totalRevenue: updatedUserProfile.totalRevenue || 0,
                    storeVisits: updatedUserProfile.storeVisits || 0,
                    leads: updatedUserProfile.leads || 0,
                    socialLinks: {
                        instagram: updatedUserProfile.socialLinks.instagram || '',
                        tiktok: updatedUserProfile.socialLinks.tiktok || '',
                        youtube: updatedUserProfile.socialLinks.youtube || '',
                        twitter: updatedUserProfile.socialLinks.twitter || '',
                        facebook: updatedUserProfile.socialLinks.facebook || '',
                        email: updatedUserProfile.socialLinks.email || '',
                        discord: updatedUserProfile.socialLinks.discord || '',
                        twitch: updatedUserProfile.socialLinks.twitch || '',
                        threads: updatedUserProfile.socialLinks.threads || '',
                        linkedin: updatedUserProfile.socialLinks.linkedin || '',
                        pinterest: updatedUserProfile.socialLinks.pinterest || '',
                        custom: updatedUserProfile.socialLinks.custom || ''
                    }
                };
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "updateUser: Something went wrong",
                });
                throw error;
            }
        }),

        //Bookings
        createBookingProduct: flow(function* (productData) {
            try {
                const result = yield axios.post(api.bookings.create, productData);
                notification.success({
                    message: "Booking product created successfully",
                });
                return result.data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "createBookingProduct: Something went wrong",
                });
                throw error;
            }
        }),

        //Digital Products
        createProduct: flow(function* (productData) {
            try {
                yield axios.post(api.products.create, productData);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "createProduct: Something went wrong",
                });
                throw error;
            }
        }),

        fetchProduct: flow(function* (productType, productId) {
            try {
                const result = yield axios.get(api.products.fetchEdit.replace(":type", productType).replace(":id", productId));
                return result.data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchProduct: Something went wrong",
                });
                throw error;
            }
        }),

        deleteCommerceProduct: flow(function* (productId) {
            try {
                yield axios.delete(api.products.deleteCommerceProduct.replace(":id", productId));
                self.products = self.products.filter((product) => product.id !== productId);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "deleteProduct: Something went wrong",
                });
                throw error;
            }
        }),

        deleteEcommerceProduct: flow(function* (productId) {
            try {
                yield axios.delete(api.ecommerceShop.deleteEcommerceProduct(productId));
                self.products = self.products.filter((product) => product.id !== productId);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "deleteProduct: Something went wrong",
                });
                throw error;
            }
        }),

        deleteShopEcommerceProduct: flow(function* (productId) {
            try {
                yield axios.delete(api.shop.deleteEcommerceProduct(productId));
                self.products = self.products.filter((product) => product.id !== productId);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "deleteProduct: Something went wrong",
                });
                throw error;
            }
        }),

        deleteProduct: flow(function* (productId) {
            try {
                yield axios.delete(api.products.delete.replace(":id", productId));
                self.products = self.products.filter((product) => product.id !== productId);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "deleteProduct: Something went wrong",
                });
                throw error;
            }
        }),

        deleteBookingProduct: flow(function* (productId) {
            try {
                yield axios.delete(api.products.deleteBooking.replace(":id", productId));
                self.products = self.products.filter((product) => product.id !== productId);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "deleteProduct: Something went wrong",
                });
                throw error;
            }
        }),

        fetchProducts: flow(function* () {
            try {
                const result = yield axios.post(api.products.fetch);
                self.products = result.data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchProducts: Something went wrong",
                });
                throw error;
            }
        }),


        // Headline Sections - Create/Update/Delete
        createHeadlineSection: flow(function* () {
            try {
                const headlineData = {
                    headline: "This is a headline",
                    user: self.me.id, // assuming the user id is stored in self.me.id
                };

                const result = yield axios.post(api.headlineSections.create, headlineData);
                self.headlineSections.push(result.data);

                return result.data; // Return the newly created headline section
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "createHeadlineSection: Something went wrong",
                });
                throw error;
            }
        }),
        updateHeadlineSection: flow(function* (headlineId, headlineData) {
            try {
                yield axios.put(api.headlineSections.update.replace(":id", headlineId), headlineData);
                self.headlineSections = self.headlineSections.map((headline) =>
                    headline.id === headlineId ? { ...headline, ...headlineData } : headline
                );
                notification.success({
                    message: "Headline updated",
                });
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "updateHeadlineSection: Something went wrong",
                });
                throw error;
            }
        }),
        deleteHeadlineSection: flow(function* (headlineId) {
            try {
                yield axios.delete(api.headlineSections.delete.replace(":id", headlineId));
                self.headlineSections = self.headlineSections.filter((headline) => headline.id !== headlineId);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "deleteHeadlineSection: Something went wrong",
                });
                throw error;
            }
        }),
        unpublishProduct: flow(function* (productId) {
            try {
                yield axios.put(api.products.unpublish.replace(":id", productId));
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "unpublishProduct: Failed to unpublish product",
                });
                throw error;
            }
        }),
        // In authStore.js
        publishProduct: flow(function* (productId) {
            try {
                yield axios.put(api.products.publish.replace(":id", productId));
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "publishProduct: Failed to publish product",
                });
                throw error;
            }
        }),
        fetchHeadlines: flow(function* () {
            try {
                const result = yield axios.get(api.headlineSections.fetch);
                self.headlineSections = result.data;

            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchHeadlines: Something went wrong",
                });
                throw error;
            }
        }),

        updateBlockOrder: flow(function* (updatedBlocks) {
            try {
                yield axios.post(api.block.update, { blocks: updatedBlocks });
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "updateBlockOrder: Something went wrong",
                });
                throw error;
            }
        }),

        fetchMetrics: flow(function* () {
            try {
                const response = yield axios.get(api.metrics.fetch);
                const metrics = response.data.metrics || [];
                const totalRevenue = response.data.totalRevenue || 0;

                if (!Array.isArray(metrics)) {
                    throw new Error('Invalid metrics data format');
                }

                self.me = {
                    ...self.me,
                    metrics: metrics,
                    totalRevenue: totalRevenue,
                    storeVisits: metrics.reduce((acc, metric) => acc + metric.visits, 0),
                    leads: metrics.reduce((acc, metric) => acc + metric.emailLeads, 0),
                };
            } catch (error) {
                console.error('Failed to fetch metrics:', error);
                notification.error({
                    message: error.response?.data?.message || 'fetchMetrics: Something went wrong',
                });
                throw error;
            }
        }),

        fetchTheme: flow(function* (username) {
            try {
                const { data } = yield axios.get(api.userProfile.fetchTheme, {
                    params: { username },
                    headers: {}, // Ensure no auth header
                });
                self.theme = data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchTheme: Something went wrong",
                });
                throw error;
            }
        }),
        saveImageStyle: flow(function* (styleData) {
            try {
                yield axios.post(api.theme.saveImageStyle, styleData);
            } catch (error) {
                console.error('Error saving image style:', error);
                notification.error({
                    message: error.response?.data?.message || "saveImageStyle: Failed to save image style.",
                });
                throw error;
            }
        }),
        saveCardStyle: flow(function* (styleData) {
            try {
                yield axios.post(api.theme.saveCardStyle, styleData);
            } catch (error) {
                console.error('Error saving card style:', error);
                notification.error({
                    message: error.response?.data?.message || "saveCardStyle: Failed to save card style.",
                });
                throw error;
            }
        }),

        saveButtonStyle: flow(function* (styleData) {
            try {
                yield axios.post(api.theme.saveButtonStyle, styleData);
            } catch (error) {
                console.error('Error saving card style:', error);
                notification.error({
                    message: error.response?.data?.message || "saveButtonStyle: Failed to save card style.",
                });
                throw error;
            }
        }),

        detachCard: flow(function* (paymentMethodId) {
            try {
                const response = yield axios.post(api.billing.detachCard, { paymentMethod: paymentMethodId });
                return response.data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'Failed to remove payment method',
                });
                throw error;
            }
        }),

        saveBackgroundColor: flow(function* (backgroundData) {
            try {
                yield axios.post(api.theme.saveBackground, backgroundData);

            } catch (error) {
                console.error('Error saving background selection:', error);
                notification.error({
                    message: error.response?.data?.message || "saveBackgroundColor: Failed to save background selection.",
                });
                throw error;
            }
        }),

        uploadBackgroundImage: flow(function* (formData) {
            try {
                const { data } = yield axios.post(api.theme.uploadBackgroundImage, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                notification.success({
                    message: "Background image uploaded successfully!",
                });
                return data;
            } catch (error) {
                console.error('Error uploading background image:', error);
                notification.error({
                    message: error.response?.data?.message || "uploadBackgroundImage: Failed to upload background image.",
                });
                throw error;
            }
        }),

        uploadBackgroundVideo: flow(function* (videoFile) {
            const formData = new FormData();
            formData.append('backgroundVideo', videoFile);

            try {
                const { data } = yield axios.post(api.theme.uploadBackgroundVideo, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                notification.success({
                    message: "Background video uploaded successfully!",
                });
                return data;
            } catch (error) {
                console.error('Error uploading background video:', error);
                notification.error({
                    message: error.response?.data?.message || "uploadBackgroundVideo: Failed to upload background video.",
                });
                throw error;
            }
        }),

        saveSelectedTheme: flow(function* (themeName, username) {
            try {
                const response = yield axios.post(api.theme.saveSelectedTheme, { themeName, username });
                return response;
            } catch (error) {
                console.error('Error saving theme:', error);
                notification.error({
                    message: error.response?.data?.message || "saveSelectedTheme: Failed to save theme.",
                });
                throw error;
            }
        }),

        getUserTheme: flow(function* () {
            try {
                const response = yield axios.get(api.theme.fetchSparkleTheme);
                return response;
            } catch (error) {
                console.error('Error saving theme:', error);
                notification.error({
                    message: error.response?.data?.message || "getUserTheme: Failed to save theme.",
                });
                throw error;
            }
        }),

        getThemeById: flow(function* () {
            try {
                const response = yield axios.get(api.theme.fetchSparkleTheme);
                return response;
            } catch (error) {
                console.error('Error saving theme:', error);
                notification.error({
                    message: error.response?.data?.message || "getThemeById: Failed to save theme.",
                });
                throw error;
            }
        }),

        addEmailUser: flow(function* (values) {
            try {
                yield axios.post(api.store.addEmailUser(values.storeName), values);
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'addEmailUser: Failed to save email user',
                });
                throw error;
            }
        }),

        createSubscription: flow(function* (priceId) {
            try {
                const response = yield axios.post(api.billing.createSubscription(), {
                    priceId,
                });
                return response.data; // Return the data from the backend
            } catch (error) {
                console.log('createSubscription: Failed to create subscription user');
                throw error;
            }
        }),

        cancelSubscription: flow(function* () {
            try {
                yield axios.post(api.billing.cancelSubscription());
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'cancelSubscription: Failed to cancel subscription',
                });
                throw error;
            }
        }),

        getCustomerBillingPlan: flow(function* () {
            try {
                const response = yield axios.get(api.billing.getBillingPlan());
                return response.data; // Return the data from the backend
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'getCustomerBillingPlan: Failed to fetch billing plan details',
                });
                throw error;
            }
        }),

        addOrUpdatePaymentMethod: flow(function* (paymentMethodId) {
            try {
                const response = yield axios.post(api.billing.addOrUpdatePaymentMethod(), {
                    paymentMethodId,
                });
                notification.success({
                    message: 'Payment method added/updated successfully',
                });
                return response.data; // Return the data from the backend
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'addOrUpdatePaymentMethod: Failed to add/update payment method',
                });
                throw error;
            }
        }),

        createStripeAccountLink: flow(function* (windowUrl, storeName, successUrl) {
            try {
                const response = yield axios.post(api.billing.createAccountLink(), {
                    windowUrl,
                    storeName,
                    successUrl,
                });

                // Store the verification status
                if (response.data.verificationStatus) {
                    self.stripeVerificationStatus = response.data.verificationStatus;
                }

                return response.data.url;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'Failed to create Stripe account link',
                });
                throw error;
            }
        }),

        checkStripeVerification: flow(function* () {
            try {
                const response = yield axios.get(api.billing.checkVerification());
                if (response.data.verificationStatus) {
                    self.stripeVerificationStatus = response.data.verificationStatus;
                }
                return response.data.verificationStatus;
            } catch (error) {
                console.error('Failed to check verification status:', error);
                return null;
            }
        }),

        deleteStripeAccount: flow(function* () {
            try {
                const response = yield axios.post(api.billing.deleteAccountLink());
                if (response.data.success) {
                    self.me.stripeAccountId = null; // Update the local user state
                    notification.success({
                        message: 'Stripe account deleted successfully',
                    });
                }
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'deleteStripeAccount: Failed to delete Stripe account',
                });
                throw error;
            }
        }),

        updateStripeAccount: flow(function* (windowUrl) {
            try {
                const response = yield axios.post(api.billing.updateAccount(), {
                    windowUrl,
                });

                // Update verification status if it exists in the response
                if (response.data?.verificationStatus) {
                    self.stripeVerificationStatus = {
                        detailsSubmitted: response.data.verificationStatus.detailsSubmitted,
                        hasExternalAccount: response.data.verificationStatus.hasExternalAccount,
                        cardPaymentsActive: response.data.verificationStatus.cardPaymentsActive,
                        transfersActive: response.data.verificationStatus.transfersActive,
                        currentlyDueFields: response.data.verificationStatus.currentlyDueFields || [],
                        eventuallyDueFields: response.data.verificationStatus.eventuallyDueFields || [],
                        pastDueFields: response.data.verificationStatus.pastDueFields || [],
                        isFullyVerified: false // We'll calculate this based on the fields
                    };

                    // Calculate isFullyVerified
                    self.stripeVerificationStatus.isFullyVerified = (
                        self.stripeVerificationStatus.detailsSubmitted &&
                        self.stripeVerificationStatus.hasExternalAccount &&
                        self.stripeVerificationStatus.cardPaymentsActive &&
                        self.stripeVerificationStatus.transfersActive &&
                        self.stripeVerificationStatus.currentlyDueFields.length === 0 &&
                        self.stripeVerificationStatus.pastDueFields.length === 0
                    );
                }

                return response.data.url;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'updateStripeAccount: Failed to create Stripe account update link',
                });
                throw error;
            }
        }),

        fetchEmails: flow(function* () {
            try {
                const response = yield axios.get(api.customers.fetchEmails);  // Use axios.get to fetch emails
                return response.data;  // Return the fetched emails data
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'fetchEmails: Failed to fetch emails',
                });
                throw error;
            }
        }),

        fetchCustomers: flow(function* () {
            try {
                const response = yield axios.get(api.customers.fetchCustomers);  // Use axios.get to fetch customers
                return response.data;  // Return the fetched customers data
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'fetchCustomers: Failed to fetch customers',
                });
                throw error;
            }
        }),

        updateDashboardVisitFlag: flow(function* () {
            try {
                yield axios.post(api.userProfile.updateDashboardVisit);
                self.me.hasVisitedDashboard = true; // Update the local state
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "updateDashboardVisitFlag: Failed to update visit flag",
                });
                throw error;
            }
        }),

        createCustomerStripeAccountLink: flow(function* (storeName, productSlug) {
            try {
                const response = yield axios.post(api.store.createCustomerPaymentIntent(), {
                    storeName,
                    productSlug,
                });
                return { success: true, data: response.data }; // Return the data from the backend, which includes clientSecret
            } catch (error) {
                return { success: false };
            }
        }),

        onPaymentSuccess: flow(function* ({ paymentIntent, name, email, storeName, productSlug, isBookingProduct, selectedDate, selectedTime }) {
            try {
                const response = yield axios.post(api.store.onPaymentSuccess(), {
                    paymentIntent,
                    name,
                    email,
                    storeName,
                    productSlug,
                    isBookingProduct,
                    selectedDate,
                    selectedTime
                });

                return response.data;
            } catch (error) {
                console.error('onPaymentSuccess: Failed to handle payment success:', error);
                throw error; // Rethrow the error so it can be handled by the caller
            }
        }),

        getDownloadLink: flow(function* (storeName, transactionId, productId) {
            try {
                const response = yield axios.get(api.store.getDownloadLink(storeName), {
                    params: transactionId ? { transactionId } : { productId },
                    responseType: 'blob',
                });
                return response.data; // Return the blob data directly
            } catch (error) {
                console.error('getDownloadLink: Failed to get download link:', error);
                throw error;
            }
        }),

        getFreeDownloadLink: flow(function* (storeName, productId) {
            try {
                const response = yield axios.get(api.store.getDownloadLink(storeName), {
                    params: { productId },
                    responseType: 'blob',
                });
                return response;
            } catch (error) {
                console.error('getFreeDownloadLink: Failed to get download link:', error);
                throw error;
            }
        }),

        processFreeDownload: flow(function* (storeName, productSlug, name, email) {
            try {
                // This call might create a record of the free download
                const response = yield axios.post(api.store.processFreeDownload(storeName), {
                    productSlug,
                    name,
                    email
                });
                return { success: true, data: response.data };
            } catch (error) {
                console.error('processFreeDownload: Failed to process free download:', error);
                return { success: false, error: error.response?.data?.message || 'Failed to process free download' };
            }
        }),

        processFreeBooking: flow(function* (storeName, productSlug, name, email, selectedDate, selectedTime) {
            try {
                const response = yield axios.post(api.store.processFreeBooking(storeName), {
                    productSlug,
                    name,
                    email,
                    selectedDate,
                    selectedTime
                });
                return { success: true, data: response.data };
            } catch (error) {
                console.error('processFreeBooking: Failed to process free booking:', error);
                return { success: false, error: error.response?.data?.message || 'Failed to process free booking' };
            }
        }),

        fetchCustomerById: flow(function* (customerId) {
            try {
                const response = yield axios.get(api.customers.fetchCustomerById(customerId));
                return response.data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'fetchCustomerById: Failed to fetch customer details',
                });
                throw error;
            }
        }),

        fetchEmailById: flow(function* (emailId) {
            try {
                const response = yield axios.get(api.customers.fetchEmailById(emailId));
                return response.data;
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || 'fetchEmailById: Failed to fetch email details',
                });
                throw error;
            }
        }),

        fetchTransactions: flow(function* () {
            try {
                const response = yield axios.get(api.transactions.fetch);
                return response.data;
            } catch (error) {
                console.error('Error fetching transactions:', error);
                notification.error({
                    message: error.response?.data?.message || 'fetchTransactions: Failed to fetch transactions',
                });
                return [];
            }
        }),

        fetchTransactionById: flow(function* (transactionId) {
            try {
                const response = yield axios.get(api.transactions.fetchById(transactionId));
                return response.data;
            } catch (error) {
                console.error('Error fetching transaction:', error);
                notification.error({
                    message: error.response?.data?.message || 'fetchTransactionById: Failed to fetch transaction',
                });
                throw error;
            }
        }),

        refundTransaction: flow(function* (transactionId) {
            try {
                yield axios.post(api.transactions.refund(transactionId));
                notification.success({
                    message: "Refund processed successfully",
                });
            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "refundTransaction: Failed to process refund",
                });
                throw error;
            }
        }),

        fetchReferralData: flow(function* () {
            try {
                const response = yield axios.get(api.referrals.fetch);
                return response.data;
            } catch (error) {
                console.error("Failed to fetch referral stats:", error);
                throw error;
            }
        }),

        updateUserProfile: flow(function* (profileData) {
            try {
                const response = yield axios.put(api.userProfile.updateDetails, profileData);
                return response.data;
            } catch (error) {
                console.error('Error updating user profile:', error);
                throw error;
            }
        }),

        updatePassword: flow(function* (passwordData) {
            try {
                const response = yield axios.put(api.userProfile.updatePassword, passwordData);
                return response.data;
            } catch (error) {
                console.error('Error updating password:', error);
                notification.error({
                    message: error.response?.data?.message || 'Failed to update password',
                });
                throw error;
            }
        }),

        updateAnalytics: flow(function* (analyticsData) {
            try {
                const response = yield axios.put(api.userProfile.updateAnalytics, analyticsData);
                return response.data;
            } catch (error) {
                console.error('Error updating analytics settings:', error);
                notification.error({
                    message: error.response?.data?.message || 'Failed to update analytics settings',
                });
                throw error;
            }
        }),

        updateAddress: flow(function* (addressData) {
            try {
                const response = yield axios.put(api.userProfile.updateAddress, addressData);
                return response.data;
            } catch (error) {
                console.error('Error updating address:', error);
                notification.error({
                    message: error.response?.data?.message || 'Failed to update address',
                });
                throw error;
            }
        }),

        updateOtherSettings: flow(function* (settingsData) {
            try {
                const response = yield axios.put(api.userProfile.updateOtherSettings, settingsData);
                return response.data;
            } catch (error) {
                console.error('Error updating settings:', error);
                notification.error({
                    message: error.response?.data?.message || 'Failed to update settings',
                });
                throw error;
            }
        }),

        saveFontStyle: flow(function* (fontStyleData) {
            try {
                const response = yield axios.post(api.theme.saveFontStyle, fontStyleData);
                notification.success({
                    message: 'Font style updated successfully',
                });
                return response.data;
            } catch (error) {
                console.error('Error saving font style:', error);
                notification.error({
                    message: error.response?.data?.message || 'Failed to save font style',
                });
                throw error;
            }
        }),

        updateVerificationStatus: flow(function* (isVerified) {
            try {
                const response = yield axios.put(api.userProfile.updateVerificationStatus, { isVerified });

                // Update the local state
                self.me.isVerified = isVerified;

                return response.data;
            } catch (error) {
                console.error('Error updating verification status:', error);
                notification.error({
                    message: error.response?.data?.message || 'Failed to update verification status',
                });
                throw error;
            }
        }),


    }));

export default AuthStore;
