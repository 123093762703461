export const ROUTE_PATH = {
    DASHBOARD: "/dashboard",
    HOME: "/",
    TOOLS: "/tools",
    INSTAGRAM: "/creator/link-in-bio-for-instagram",
    TWITTER: "/creator/link-in-bio-for-twitter",
    TIKTOK: "/creator/link-in-bio-for-tiktok",
    LINKEDIN: "/creator/link-in-bio-for-linkedin",
    TSHIRT: "/create/t-shirts",
    TSHIRTCUSTOMIZER: "/create/t-shirts/customize",
    INCOME: "/income",
    SETTINGS: "/settings",
    PROFILE: "/settings/profile",
    USERPROFILE: "/profile",
    CUSTOMERS: "/customers",
    BILLING: "/settings/billing",
    PAYMENTS: "/settings/payments",
    NOTIFICATIONS: "/settings/notifications",
    MYSTORE: "/store",
    SHOP: "/shop",
    SHOP_CATEGORIES: "/shop/home",
    DESIGN: "/design",
    PRODUCTS: "/products",
    ONBOARDING: "/onboarding",
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    FORGOT_PASSWORD: "/auth/forgot",
    REFERRALS: "/referrals"
};

export const STORE_PATH = {
    SHOPDASHBOARD: "/store/dashboard",
    ORDERS: "/view/orders",
    ADD_PRODUCTS: "/store/products",
    VIEW_PRODUCTS: "/view/products",
    COLLECTIONS: "/store/collections"
}

export const ROUTE_PRODUCT_PATH = {
    URL_PRODUCT: "/products/add-products/url",
    EDIT_URL_PRODUCT: "/products/edit/url/:id",
    DIGITAL_PRODUCT: "/add-products/digital-product",
    EDIT_DIGITAL_PRODUCT: "/products/edit/digital/:id",
    COACHING_CALL: "/add-products/coaching-call",
    CUSTOM_PRODUCT: "/add-products/custom-product",
    EDIT_CUSTOM_PRODUCT: "/products/edit/custom/:id",
    EMAIL_PRODUCT: "/add-products/email-product",
    EDIT_EMAIL_PRODUCT: "/products/edit/email/:id",
    VIDEO_PRODUCT: "/add-products/video-product",
    EDIT_VIDEO_PRODUCT: "/products/edit/video/:id",
    REFERRAL_PRODUCT: "/add-products/referral-product",
    EDIT_REFERRAL_PRODUCT: "/products/edit/referral/:id",
};

export const ROUTE_LEGAL_PATH = {
    LEGAL: "/legal",
    PRIVACY_POLICY: "/legal/privacy-policy",
    TERMS_OF_SERVICE: "/legal/terms-of-service",
    COOKIE_POLICY: "/legal/cookie-policy",
    REFUND_POLICY: "/legal/refund-policy",
    DATA_DELETION_POLICY: "/legal/data-deletion-policy",
};