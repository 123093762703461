import { flow, types } from "mobx-state-tree";
import { notification } from "antd";
import { api } from "../constants/api";
import { axios } from "../services/http/axios";

const ShopAuthStore = types
    .model("ShopAuthStore", {
        clientSecret: types.maybeNull(types.string),

        me: types.maybeNull(
            types.model("MeModel", {
                id: types.maybeNull(types.string),
                userName: types.maybeNull(types.string),
                displayName: types.maybeNull(types.string),
                email: types.maybeNull(types.string),
                avatar: types.maybeNull(types.string),
            }),
        ),

    })
    .actions((self) => ({
        setToken: (token) => {
            localStorage.setItem("SparkleAuthToken", token);
        },
        setUserData: (data) => {
            self.register = data;
        },
        setProfileData: (data) => {
            self.me = data;
        },

        checkUserNameAndEmail: flow(function* (values) {
            try {
                return yield axios.post(api.auth.check, values);
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "checkUserNameAndEmail: Something went wrong",
                });
                throw error;
            }
        }),

        onLogin: flow(function* (values) {
            try {
                const result = yield axios.post(api.shopAuth.signIn, values);
                self.setToken(result?.data?.token);

                return true;
            } catch (error) {
                notification.error({
                    message:
                        error.response?.data?.message || "onLogin: Something went wrong",
                });
                throw error;
            }
        }),
        logout: () => {
            self.me = null;
            localStorage.clear();
        },
        whoami: flow(function* () {
            try {
                const result = yield axios.get(api.shopAuth.whoami);

                self.me = result.data?.profile;
            } catch (error) {
                console.log(error);
            }
        }),

        fetchUserDetails: flow(function* () {
            try {
                const result = yield axios.get(api.userProfile.fetch);
                const userProfile = result.data;

                return userProfile;

            } catch (error) {
                notification.error({
                    message: error.response?.data?.message || "fetchUserDetails: Something went wrong",
                });
                throw error;
            }
        }),

    }));

export default ShopAuthStore;
